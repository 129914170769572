import React from 'react';

import AccountIcon from '../../public/images/menu/AccountIcon';
import LiveChatIcon from '../../public/images/menu/LiveChatIcon';
import ContactSupportIcon from '../../public/images/menu/ContactSupportIcon';
import HelpCenterIcon from '../../public/images/menu/HelpCenterIcon';
import BookCallIcon from '../../public/images/menu/BookCallIcon';
import ChangelogIcon from '../../public/images/menu/ChangelogIcon';
import FeedbackIcon from '../../public/images/menu/FeedbackIcon';


interface SeparatorItem {
  type: "separator";
  label: string;
  hidden?: boolean;
}

interface LinkItem {
  type: "link";
  path: string;
  name: string;
  icon: string;
  tip: string;
  hidden?: boolean;
  turbo_frame?: string;
  active?: boolean;
}

type MenuItem = SeparatorItem | LinkItem;

export const menuItems = (aiType: string): MenuItem[] => [
  {
    type: "separator",
    label: "Prospects",
  },
  {
    path: "/data/audiences/new",
    name: "Search",
    icon: "search",
    type: "link",
    tip: "Search",
  },
  {
    path: "/data/contacts",
    name: "Contacts",
    icon: "contacts",
    type: "link",
    tip: "Contacts",
  },
  {
    path: "/data/companies/ag-grid",
    name: "Companies",
    icon: "companies",
    type: "link",
    tip: "Companies",
  },
  {
    path: "/data/lists",
    name: "Lists",
    icon: "lists",
    type: "link",
    tip: "Lists",
  },
  {
    path: "/data/sources",
    name: "Visitors",
    icon: "visitors",
    type: "link",
    tip: "Visitors",
  },
  {
    path: "/data/keywords",
    name: "Intent",
    icon: "intents",
    type: "link",
    tip: "Intent",
  },
  {
    type: "separator",
    label: "Engage",
  },
  {
    path: "/path/to/inbox",
    name: "Inbox",
    icon: "inbox",
    type: "link",
    tip: "Inbox",
    hidden: true,
  },
  {
    path: "/conversations",
    name: "WebChat",
    icon: "webchats",
    type: "link",
    tip: "WebChat",
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    icon: "campaigns",
    type: "link",
    tip: "Campaigns",
  },
  // {
  //   path: "/goengage",
  //   name: "Go Engage",
  //   icon: "engage_campaign",
  //   type: "link",
  //   turbo_frame: "_top",
  //   tip: "Go Engage",
  // },
  {
    path: "/bots/leads",
    name: "Routing Bots",
    icon: "bot_tasks",
    type: "link",
    tip: "Routing Bots",
  },
  {
    type: "separator",
    label: "Pipeline",
  },
  {
    path: "/goschedule",
    turbo_frame: "_top",
    name: "Appointments",
    icon: "appointments",
    type: "link",
    tip: "Appointments"
  },
  {
    path: "/flow",
    name: "Connections",
    icon: "connections",
    type: "link",
    tip: "Connections",
  },
  {
    type: "separator",
    label: "AI Tools",
  },
  {
    path: "/coach",
    turbo_frame: "_top",
    name: "Sales Coach",
    icon: "bot_tasks",
    type: "link",
    tip: "Sales Coach",
  },
  {
    path: "/path/to/segments",
    name: "Segments",
    icon: "enable_tools",
    type: "link",
    tip: "Segments",
    hidden: true,
  },
  {
    path: "/goai/models",
    name: "Models",
    icon: "models",
    type: "link",
    tip: "Models",
    hidden: true,
  },
  // {
  //   path: "/goai/conversations",
  //   name: "Conversations",
  //   icon: "ai_conversations",
  //   type: "link",
  //   tip: "Conversations",
  // },
  {
    path: "/path/to/templates",
    name: "Templates",
    icon: "ai_templates",
    type: "link",
    tip: "Templates",
    hidden: true,
  },
  {
    path: "/goai/templates",
    name: "Prompt Templates",
    icon: "ai_prompt_templates",
    type: "link",
    tip: "Prompt Templates",
    hidden: true,
  },
  {
    type: "separator",
    label: "Content",
  },
  {
    path: "/articles",
    name: "Articles",
    icon: "articles",
    type: "link",
    tip: "Articles",
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "reports",
    type: "link",
    tip: "Reports",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "settings",
    type: "link",
    tip: "Settings",
  },
];

type IconComponent = React.ComponentType<React.SVGProps<SVGSVGElement>>;

interface MenuItemType {
  id: string;
  icon: IconComponent;
  title: string;
  href?: string;
  onClick?: () => void;
  addDivider?: boolean;
  isEmail?: boolean;
}


export const userProfileMenuLinks: MenuItemType[] = [
  {
    id: "account",
    icon: AccountIcon,
    title: "Account",
    href: "https://uui.graph8.com/apps/",
    addDivider: true,
  },
  {
    id: "live_chat",
    icon: LiveChatIcon,
    title: "Live Chat",
    onClick: () => {
      const iframeDoc =
          // @ts-ignore
          document.getElementById("chaskiqPrime")?.contentDocument;
      if (iframeDoc?.getElementById("chaskiq-prime")) {
        iframeDoc.getElementById("chaskiq-prime").click();
      }
    },
  },
  {
    id: "contact_support",
    icon: ContactSupportIcon,
    title: "Email Support",
    href: "mailto:support@graph8.com?subject=Contact%20graph8%20Support",
    isEmail: true,
  },
  {
    id: "help_center",
    icon: HelpCenterIcon,
    title: "Help Center",
    href: "https://helpcenter.graph8.com/",
    addDivider: true,
  },
  // {
  //   id: "product_tour",
  //   imageUrl: "/images/menu/product.svg",
  //   imageAlt: "product tour",
  //   title: "Product Tour",
  //   href: "/",
  //   isDisabled: true,
  // },
  {
    id: "changelog",
    icon: ChangelogIcon,
    title: "Changelog",
    href: "/changelog",
  },
  {
    id: "feedback",
    icon: FeedbackIcon,
    title: "Feedback",
    href: "/feedback",
    addDivider: true,
  },
  {
    id: "book_call",
    icon: BookCallIcon,
    title: "Book a Call",
    href: "https://schedule.graph8.com/team/graph8/support",
  },
];
