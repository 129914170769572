import React from "react";
import type { IconProps } from "./types";

const CompaniesIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={stroke}
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round">
    <path d="M3 21h18" />
    <path d="M5 21V7l8-4v18" />
    <path d="M19 21V11l-6-4" />
    <path d="M9 9h1" />
    <path d="M9 13h1" />
    <path d="M9 17h1" />
    <path d="M14 13h1" />
    <path d="M14 17h1" />
  </svg>
);

export default CompaniesIcon;
